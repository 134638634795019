<template>
	<v-app>
		<transition name="page" mode="out-in">
			<router-view />
		</transition>

		<v-snackbar v-model="snackbar">{{ getSnackbarMsg }}</v-snackbar>
	</v-app>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	data: () => ({
		snackbar: false,
	}),
	computed: {
		...mapGetters(['getSnackbar', 'getSnackbarMsg']),
	},
	watch: {
		getSnackbar() {
			this.snackbar = this.getSnackbar
		},
		snackbar() {
			if (!this.snackbar) {
				this.$store.dispatch('setSnackbar', {
					status: false,
					msg: '',
				})
			}
		},
	},
}
</script>

<style lang="scss">
html {
	overflow-y: auto;
}

.toolbar {
	.v-toolbar__content {
		padding: 0 !important;
	}
}

.page-enter-active,
.page-leave-active {
	transition: opacity 0.5s, transform 0.5s;
}
.page-enter,
.page-leave-to {
	opacity: 0;
	transform: translateX(30%);
}
</style>
