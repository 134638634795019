import Http from '@/utils/request'
import { setStorage, getStorage } from '@/utils/localStorage'

export default {
	async login({ commit, dispatch }, userInfo) {
		dispatch('setLoading', true, { root: true })

		try {
			const { data } = await Http.post('auth/login', {
				...userInfo,
			})

			setStorage('token', data.token)
			commit('token', data.token)
			dispatch('setLoading', false, { root: true })
		} catch (e) {
			dispatch('setLoading', false, { root: true })
			throw e
		}
	},
	async loginGoogle({ commit, dispatch }, token) {
		dispatch('setLoading', true, { root: true })

		try {
			const { data } = await Http.post('auth/google', token)

			setStorage('token', data.token)
			commit('token', data.token)
			dispatch('setLoading', false, { root: true })
		} catch (e) {
			dispatch('setLoading', false, { root: true })
			throw e
		}
	},
	logout({ commit }) {
		window.localStorage.clear()
		commit('logout')
	},
	async me({ commit }) {
		try {
			const { data } = await Http.get('user/me', {
				params: {
					id: getStorage('id'),
				},
			})

			commit('me', data)
		} catch (e) {
			throw e
		}
	},
	async edit({ dispatch }, params) {
		try {
			await Http.put('user/edit', params)
			dispatch('me')
		} catch (e) {
			throw e
		}
	},
	async changeAvatar({ dispatch }, avatar) {
		try {
			let formData = new FormData()

			formData.append('avatar', avatar)
			await Http.post('user/avatar/edit', formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})

			dispatch('me')
		} catch (e) {
			throw e
		}
	},
}
