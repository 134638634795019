export default {
	report: state => state.report,
	foundReports: state => state.foundReports,
	populatingRegisterPendency: state => state.informations,
	cards: state => state.cards,
	details: state => state.details,
	getDays: state => state.days,
	pendency: state => state.pendency,
	pendencyBool: state => state.pendencyBool,
}
