import Vue from 'vue'
import App from './App.vue'
import GoogleAuth from '@/config/google_oAuth.js'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

// Vue.component('vue-simplemde', VueSimplemde)

const gauthOption = {
	clientId:
		'561834925813-qjtereq6oa7q06n15m6joasg2qn2p7sj.apps.googleusercontent.com',
	scope: 'profile email',
	prompt: 'select_account',
}
Vue.use(GoogleAuth, gauthOption)
Vue.config.productionTip = false

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App),
}).$mount('#app')
