<template>
	<div class="profile">
		<div class="profile__avatar">
			<v-avatar color="transparent" size="135px" class="mb-5">
				<img alt="Profile image" :src="showAvatar" />
			</v-avatar>
			<v-btn
				class="profile__avatar--edit"
				fab
				dark
				color="#1E90FF"
				width="32px"
				height="32px"
				@click="upload"
			>
				<v-icon dark>
					mdi-pencil
				</v-icon>
			</v-btn>
			<strong>{{ me.name }}</strong>
			<input
				type="file"
				style="display: none"
				ref="input"
				@change="changeImage"
			/>
		</div>

		<v-form class="d-flex flex-wrap pt-3" @submit.prevent="save">
			<v-col cols="12" md="6" class="pt-0">
				<v-text-field
					v-model="name"
					label="Nome"
					required
					filled
					hide-details
				/>
			</v-col>
			<v-col cols="12" md="6" class="pt-0">
				<v-text-field
					v-model="me.email"
					label="E-mail"
					required
					filled
					hide-details
					disabled
				/>
			</v-col>

			<v-col cols="12" md="12" class="pt-0">
				<v-text-field
					v-model="password"
					:label="
						!me.hasPassword ? 'Informe uma senha' : 'Informa uma nova senha'
					"
					required
					filled
					hide-details
					:type="show ? 'text' : 'password'"
					:append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
					@click:append="show = !show"
				/>
			</v-col>

			<v-btn
				class="text-center ml-3 mr-3"
				color="#1e90ff"
				dark
				depressed
				height="36"
				width="83"
				type="submit"
			>
				SALVAR
			</v-btn>
		</v-form>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	data: () => ({
		show: false,
		name: '',
		img: '',
		avatar: '',
		password: '',
	}),
	computed: {
		...mapGetters({ me: 'user/getMe' }),
		showAvatar() {
			return this.avatar ? this.avatar : this.me.avatar
		},
	},
	mounted() {
		this.name = this.me.name
		this.avatar = this.me.avatar
	},
	methods: {
		async save() {
			try {
				const { name, password, img } = this
				await this.$store.dispatch('user/edit', { name, password, img })

				this.$store.dispatch('setSnackbar', {
					status: true,
					message: 'Dados alterados com sucesso!',
				})
			} catch (e) {
				console.log(e)
			}
		},
		upload() {
			this.$refs.input.click()
		},
		changeImage(e) {
			this.img = e.target.files[0]
			this.avatar = URL.createObjectURL(e.target.files[0])

			this.$store.dispatch('user/changeAvatar', e.target.files[0])
		},
	},
}
</script>

<style lang="scss" scoped>
.profile {
	&__avatar {
		display: flex;
		flex-direction: column;
		align-items: center;
		color: #252733;
		&--edit {
			position: absolute;
			left: 55%;
			bottom: 63%;
		}
	}
}
</style>
