export default {
	logout(state) {
		state.me = {}
	},
	me(state, data) {
		state.me = data
	},
	token(state, data) {
		state.token = data
	},
}
