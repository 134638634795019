import AppBar from '@/components/organisms/AppBar'
import Drawer from '@/components/organisms/Drawer'

const routes = [
	{
		path: '/',
		name: 'Login',
		component: () =>
			import(/* webpackChunkName: "login" */ '@/pages/Public/Login'),
	},
	{
		path: '/dashboard',
		component: () =>
			import(/* webpackChunkName: "dashboard_index" */ '@/pages/Private/App'),
		meta: { requiresAuth: true },
		children: [
			{
				path: '/',
				name: 'dashboard',
				meta: { requiresAuth: true },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "dashboard" */ '@/pages/Private/App/Dashboard'
						),
					appbar: AppBar,
					drawer: Drawer,
				},
			},
			{
				path: '/create/pendency',
				name: 'create-pendency',
				meta: { requiresAuth: true },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "dashboard" */ '@/pages/Private/App/Dashboard'
						),
					appbar: AppBar,
					drawer: Drawer,
					dialog: () =>
						import(
							/* webpackChunkName: "create-pendency" */ '@/pages/Private/App/Dashboard/CreatePendency'
						),
				},
			},
			{
				path: '/details/pendency/:id',
				name: 'details-pendency',
				meta: { requiresAuth: true },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "dashboard" */ '@/pages/Private/App/Dashboard'
						),
					appbar: AppBar,
					drawer: Drawer,
					dialog: () =>
						import(
							/* webpackChunkName: "details-pendency" */ '@/pages/Private/App/Dashboard/DetailsPendency'
						),
				},
			},
		],
	},
	{
		path: '/equipes',
		component: () =>
			import(/* webpackChunkName: "teams-index" */ '@/pages/Private/App/Teams'),
		children: [
			{
				path: '/',
				name: 'teams',
				components: {
					default: () =>
						import(
							/* webpackChunkName: "teams-index" */ '@/pages/Private/App/Teams/Dashboard'
						),
					appbar: AppBar,
					drawer: Drawer,
				},
			},
			{
				path: '/equipes/nova-vaga',
				name: 'job-vacancies',
				meta: { requiresAuth: true },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "teams" */ '@/pages/Private/App/Teams/Dashboard'
						),
					appbar: AppBar,
					drawer: Drawer,
					dialog: () =>
						import(
							/* webpackChunkName: "job-vacancies" */ '@/pages/Private/App/Teams/JobVacancies'
						),
				},
			},
			{
				path: '/equipes/novo-recurso',
				name: 'new-resource',
				meta: { requiresAuth: true },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "teams" */ '@/pages/Private/App/Teams/Dashboard'
						),
					appbar: AppBar,
					drawer: Drawer,
					dialog: () =>
						import(
							/* webpackChunkName: "new-resource" */ '@/pages/Private/App/Teams/NewResource'
						),
				},
			},
			{
				path: '/equipes/nova-equipe',
				name: 'new-team',
				meta: { requiresAuth: true },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "teams" */ '@/pages/Private/App/Teams/Dashboard'
						),
					appbar: AppBar,
					drawer: Drawer,
					dialog: () =>
						import(
							/* webpackChunkName: "new-team" */ '@/pages/Private/App/Teams/NewTeam'
						),
				},
			},
		],
	},
]

export default routes
