import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import { getStorage } from '@/utils/localStorage'

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (getStorage('token') == null) {
			next({
				path: '/',
				params: { nextUrl: to.fullPath },
			})
		} else {
			next()
		}
	} else {
		next()
	}
})

export default router
