<template>
	<v-app-bar app flat height="75" color="header">
		<div class="side-bar">
			<v-text-field
				solo
				type="text"
				placeholder="Procurar"
				prepend-inner-icon="search"
				hide-details
				flat
				class="side-bar__search"
			/>

			<v-spacer class="side-bar__spacer" />

			<div class="buttons">
				<v-btn icon class="buttons__button">
					<v-icon>notifications</v-icon>
				</v-btn>

				<v-btn icon class="buttons__button">
					<v-icon>settings</v-icon>
				</v-btn>
			</div>

			<div class="avatar">
				<v-avatar size="30px" color="#CCC">
					<v-img :src="me.avatar" v-if="me.avatar" />
					<span v-else>{{ getSub }}</span>
				</v-avatar>

				<span class="avatar__name">{{ me.name }}</span>
			</div>

			<v-menu
				offset-x
				offset-y
				content-class="mt-2 arrow-top"
				transition="scale-transition"
				origin="top right"
				left
				:close-on-content-click="false"
				:min-width="150"
			>
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-on="on" v-bind="attrs">
						<v-icon color="blue">keyboard_arrow_down</v-icon>
					</v-btn>
				</template>

				<v-list dense>
					<v-list-item link @click="openModal">
						<v-list-item-icon>
							<v-icon>person_pin</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title v-text="`Minha área`" />
						</v-list-item-content>
					</v-list-item>

					<v-divider />

					<v-list-item @click="logout">
						<v-list-item-icon>
							<v-icon>login</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title v-text="`Logout`" />
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-menu>
			<MyAreaModal :dialog="dialog" @closed="resetDialog" />
		</div>
	</v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'
import MyAreaModal from '@/pages/Private/App/Dashboard/MyArea'
// import MyAreaModal from '@/components/unique/Dashboard/MyAreaModal'

export default {
	data: () => ({
		dialog: false,
	}),
	components: { MyAreaModal },
	computed: {
		...mapGetters({ me: 'user/getMe' }),
		getSub() {
			return this.me.name && this.me.name.substr(0, 1)
		},
	},
	methods: {
		async logout() {
			this.$store.dispatch('user/logout')
			this.$router.push('/')
		},
		openModal() {
			this.dialog = true
		},
		resetDialog() {
			this.dialog = false
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.side-bar {
	max-width: 1250px;
	width: 100%;
	display: flex;
	margin: 0 auto;
	&__search {
		@media (max-width: $sm) {
			display: none;
		}
	}
	&__spacer {
		@media (min-width: $sm) {
			display: none;
		}
	}
	.buttons {
		margin-right: 10px;
		&__button {
			&::after {
				content: '';
				border-right: 1px solid #c4c7d7;
				padding-bottom: 3px;
				height: 23px;
			}
		}
	}

	.avatar {
		display: flex;
		align-items: center;
		&__name {
			margin-left: 12px;
			font-size: 14px;
			line-height: 20px;
			color: rgba(0, 0, 0, 0.6);
		}
	}
}

.arrow-top {
	contain: initial;
	overflow: initial;
	box-shadow: 0 0 10px #ccc;
	&::before {
		top: -16px;
		right: 20px;
		border: solid transparent;
		content: ' ';
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: rgba(255, 255, 255, 0);
		border-bottom-color: #fff;
		border-width: 8px;
		margin-left: -8px;
	}
}
</style>
