export default {
	report: [],
	foundReports: [],
	informations: [],
	details: [],
	cards: {},
	days: [],
	pendency: {},
	pendencyBool: false,
}
