import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { light, dark } from './themes'

Vue.use(Vuetify)

export default new Vuetify({
	theme: {
		themes: {
			light,
			dark,
		},
		dark: false,
	},
})
