<template>
	<v-navigation-drawer mini-variant app color="primary">
		<v-list>
			<v-list-item two-line>
				<v-img src="@/assets/logo-short.svg" />
			</v-list-item>

			<v-divider style="margin-top: 3px " color="#fff" />

			<v-list-item link>
				<v-list-item-icon>
					<v-icon color="#fff">dashboard</v-icon>
				</v-list-item-icon>

				<v-list-item-content>
					<v-list-item-title>Dashboard</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item link>
				<v-list-item-icon>
					<v-icon color="#fff">supervisor_account</v-icon>
				</v-list-item-icon>

				<v-list-item-content>
					<v-list-item-title>Usuário</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item link>
				<v-list-item-icon>
					<v-icon color="#fff">insert_chart</v-icon>
				</v-list-item-icon>

				<v-list-item-content>
					<v-list-item-title>Gráfico</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item link>
				<v-list-item-icon>
					<v-icon color="#fff">library_books</v-icon>
				</v-list-item-icon>

				<v-list-item-content>
					<v-list-item-title>Lista</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item link>
				<v-list-item-icon>
					<v-icon color="#fff">event_seat</v-icon>
				</v-list-item-icon>

				<v-list-item-content>
					<v-list-item-title>Office</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item link @click="teams">
				<v-list-item-icon>
					<v-icon color="#fff">groups</v-icon>
				</v-list-item-icon>

				<v-list-item-content>
					<v-list-item-title>Teams</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
export default {
	methods: {
		teams() {
			this.$router.push('/equipes')
		},
	},
}
</script>
