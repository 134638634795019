import Http from '@/utils/request'
import { getYear, getMonth } from 'date-fns'

export default {
	async getReport({ commit }, id) {
		try {
			const { data } = await Http.get('pendencies/report', {
				params: {
					id,
				},
			})

			commit('getReport', data)
		} catch (e) {
			throw e
		}
	},
	async queryReports({ commit, rootState }, q) {
		rootState.pendencies.foundReports = []
		try {
			const { data } = await Http.get('pendencies/search', {
				params: {
					q,
				},
			})
			commit('setFoundReports', data)
		} catch (e) {
			throw e
		}
	},
	async populatingRegisterPendency({ commit }) {
		try {
			const { data } = await Http.get('pendencies/info')

			commit('populatingRegisterPendency', data)
		} catch (e) {
			throw e
		}
	},
	async createPendency({}, pendency) {
		try {
			await Http.post('pendencies/create', pendency)
		} catch (e) {
			throw e
		}
	},
	async editPendency({}, pendency) {
		try {
			await Http.put('pendencies/edit', pendency)
		} catch (e) {
			throw e
		}
	},
	async getInfoCards({ commit }, id) {
		try {
			const { data } = await Http.get('pendencies/info/cards', {
				params: {
					id,
				},
			})

			commit('getInfoCards', data)
		} catch (e) {
			throw e
		}
	},
	async getTypes({}, id) {
		try {
			const { data } = await Http.get('pendencies/types', {
				params: {
					id,
				},
			})

			return data
		} catch (e) {
			throw e
		}
	},

	async registerTime({}, info) {
		try {
			await Http.post('pendencies/create/item', info)
		} catch (e) {
			throw e
		}
	},
	async detailsPendency({ commit }, id) {
		try {
			const { data } = await Http.get('pendencies/get/pendency', {
				params: {
					id,
				},
			})

			commit('detailsPendency', data)
		} catch (e) {
			throw e
		}
	},
	async getDays(
		{ commit },
		{ id, month = getMonth(new Date()) + 1, year = getYear(new Date()) }
	) {
		try {
			const { data } = await Http.get('pendencies/get/days', {
				params: { id, month, year },
			})

			const days = []

			data.map(item => {
				const day = item.day.split('T')[0]
				days.push({ day, hours: item.hours })
			})

			commit('setDays', days)
		} catch (e) {
			throw e
		}
	},
	async deleteItem({ dispatch, rootState }, { itemId, pendencyId }) {
		try {
			const { employee_id } = rootState.user.me

			const { data } = await Http.delete(`pendencies/item/delete/${itemId}`)
			dispatch(
				'setSnackbar',
				{ status: true, message: data.message },
				{ root: true }
			)
			dispatch('detailsPendency', pendencyId)
			dispatch('getInfoCards', employee_id)
			dispatch('getDays', { id: employee_id })
		} catch (e) {
			throw e
		}
	},
	getPendency({ commit }, pendency) {
		commit('setPendency', pendency)
	},
	clearPendencyBool({ commit }, pendencyBool) {
		commit('setClearPendency', pendencyBool)
	},
}
