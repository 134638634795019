<template>
	<v-dialog v-model="showModal" width="860px" height="494px">
		<v-card class="my-area">
			<v-btn class="my-area__close" icon @click="closeModal">
				<v-icon>
					close
				</v-icon>
			</v-btn>
			<div class="my-area__nav">
				<h2>Minha área</h2>

				<v-tabs
					v-model="tab"
					:vertical="!$vuetify.breakpoint.mobile"
					fixed-tabs
					show-arrows
				>
					<v-tab style="justify-content: space-between;">
						<span>Perfil</span>
						<v-icon v-if="!$vuetify.breakpoint.mobile">
							chevron_right
						</v-icon>
					</v-tab>
					<v-tab style="justify-content: space-between;">
						Atestados
						<v-icon v-if="!$vuetify.breakpoint.mobile">
							chevron_right
						</v-icon>
					</v-tab>
					<v-tab style="justify-content: space-between;">
						Horas extras
						<v-icon v-if="!$vuetify.breakpoint.mobile">
							chevron_right
						</v-icon>
					</v-tab>
				</v-tabs>
			</div>

			<div class="my-area__content">
				<v-tabs-items v-model="tab">
					<v-tab-item>
						<Profile />
					</v-tab-item>
				</v-tabs-items>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
import Profile from '@/components/unique/MyArea/Profile'

export default {
	data: () => ({
		password: '',
		newPassword: '',
		name: '',
		email: '',
		showModal: false,
		tab: null,
	}),
	props: {
		dialog: Boolean,
	},

	components: { Profile },
	mounted() {
		this.showModal = this.dialog
	},
	watch: {
		dialog() {
			this.showModal = this.dialog
		},
	},
	methods: {
		closeModal() {
			this.showModal = false
			this.$emit('closed')
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.my-area {
	padding: 24px 10px;
	display: flex;
	justify-content: space-between;
	position: relative;
	@media (max-width: $sm) {
		flex-direction: column;
	}

	&__close {
		position: absolute;
		right: 0;
		top: 0;
		margin-top: 10px;
		margin-right: 10px;
		z-index: 9;
	}
	&__nav {
		border: 1px solid #dfe0eb;
		border-radius: 8px;
		width: 30%;
		@media (max-width: $sm) {
			width: 100%;
			height: auto;
			padding: 0;
			margin-top: 30px;
			margin-bottom: 30px;
		}
		height: 345px;
		padding: 30px 0 0 0px;
		h2 {
			padding-left: 20px;
			font-size: 20px;
			@media (max-width: $sm) {
				display: none;
			}
		}
	}
	&__content {
		width: 65%;
		@media (max-width: $sm) {
			width: 100%;
		}
	}
}
</style>
