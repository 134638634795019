export default {
	getReport(state, data) {
		state.report = data
	},
	setFoundReports(state, data) {
		state.foundReports = data
	},
	populatingRegisterPendency(state, informations) {
		state.informations = informations
	},
	getInfoCards(state, data) {
		state.cards = data
	},
	detailsPendency(state, data) {
		state.details = data
	},
	setDays(state, data) {
		state.days = data
	},
	setPendency(state, data) {
		state.pendency = data
	},
	setClearPendency(state, data) {
		state.pendencyBool = data
	},
}
